'use strict';

/////////////////////////////////////////
//STATICSICS
const currentDateInDays = Date.now();
const dateStartWorkingExp = new Date('2007-04-01').getTime();
const dateStartInstalling = new Date('2018-04-01').getTime();
const daysExperience =
  (currentDateInDays - dateStartWorkingExp) / (24 * 60 * 60 * 1000);
const daysExperienceHeatPumps =
  (currentDateInDays - dateStartInstalling) / (24 * 60 * 60 * 1000);
const clients = document.querySelector('.happyClients');
const equipments = document.querySelector('.numEquipment');
const yearExp = document.querySelector('.yearsExp');
const numPipes = document.querySelector('.numberPipes');

//Update number of clients
export const updateClients = function () {
  const calcNumclients = function () {
    const dayForOneClient = 19;
    const numClientsUpdated = Math.round(daysExperience / dayForOneClient);
    return numClientsUpdated;
  };
  clients.textContent = calcNumclients();
};

//Update number of heat pumps

export const updateHeatPumps = function () {
  const calcHeatPumps = function () {
    const dayForHeatPump = 120;
    const numHeatPumpUpdated = Math.round(
      daysExperienceHeatPumps / dayForHeatPump
    );
    console.log(numHeatPumpUpdated);
    return numHeatPumpUpdated;
  };
  equipments.textContent = calcHeatPumps();
};

//Update number of years Experience

export const updateYearsExperience = function () {
  const calcYearsExperience = function () {
    const currentDate = new Date().getFullYear();
    const dateStartWorkingExp = new Date('2007-04-01').getFullYear();
    const daysExperience = currentDate - dateStartWorkingExp;
    return daysExperience;
  };
  yearExp.textContent = calcYearsExperience();
};

//Update number of pipe meters

export const updateNumPipes = function () {
  const calcNumPipes = function () {
    const metersPerDay = 10000 / 365;
    const newNumPipes = Math.round(daysExperience * metersPerDay);
    return newNumPipes;
  };
  // console.(calcHeatPumps());
  numPipes.textContent = calcNumPipes();
  return calcNumPipes();
};

// export const counterNumPipes = function (startNum) {
//   let startValue = startNum;
//   const duration = 24 * 60 * 1000;

//   let counter = setInterval(() => {
//     startValue += 1;
//     numPipes.textContent = startValue;
//   }, duration);
// };

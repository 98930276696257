'use strict';

export const reviewWindowOpenClose = function () {
  const review = document.querySelector('.review');
  const overlay = document.querySelector('.overlay');
  const btnCloseReview = document.querySelector('.btn--close-review');
  const btnOpenReview = document.querySelector('.btn--review');

  //open popup
  const openReview = function (e) {
    e.preventDefault();
    review.classList.remove('hidden');
    overlay.classList.remove('hidden');
  };

  //close popup
  const closeReview = function () {
    review.classList.add('hidden');
    overlay.classList.add('hidden');
  };

  //click to open popup
  btnOpenReview.addEventListener('click', openReview);
  //click to close popup
  btnCloseReview.addEventListener('click', closeReview);
  overlay.addEventListener('click', closeReview);
  //key to close popup
  document.addEventListener('keydown', function (e) {
    if (e.key === 'Escape' && !review.classList.contains('hidden')) {
      closeReview();
    }
  });
};

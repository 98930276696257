import {
  BIG_ANIMATION_DELAY,
  DEFAULT_ANIMATION,
  DEFAULT_ANIMATION_DELAY,
  TERTIARY_ANIMATION,
  SECONDARY_ANIMATION,
} from '../config';
import { compositionAnimation } from '../helpers';

const howContainer = document.querySelector('.how__container');
const howElements = document.querySelectorAll('.how__container__item');
// console.log(howElements);

export const howCaller = function () {
  howElements.forEach((item, i) => {
    const howAnimations = function (entries, observer) {
      const [entry] = entries;
      if (!entry.isIntersecting) return;
      item.classList.add(TERTIARY_ANIMATION);

      // item.style.animationDelay = BIG_ANIMATION_DELAY;
      //STOP OBSERVING
      observer.unobserve(entry.target);
    };
    const howBoxObserver = new IntersectionObserver(howAnimations, {
      root: null,
      threshold: 0,
      // rootMargin: '100px',
    });

    const curItem = document.querySelector(`.how__container__item--${i + 1}`);
    // console.log(curItem);
    howBoxObserver.observe(curItem);
  });
};

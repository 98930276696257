'use strict';

import * as model from './model.js';
import * as footer from './views/footer.js';
import * as statistics from './views/statistics.js';
import * as header from './views/header';
import * as popup from './views/popup';
import * as mobileNav from './views/mobilenav';
import * as review from './views/review';
import * as how from './views/how';
import * as swiper from './views/swiper';
import * as swiper2 from './views/swiper2';
import * as facebookSDK from './views/instagramAPI.js';
import 'parcel-plugin-data-src';

const init = function () {
  model.lazyLoadImg();
  model.smoothScrolling();
  header.headerFade();
  statistics.updateClients();
  statistics.updateHeatPumps();
  statistics.updateYearsExperience();
  statistics.updateNumPipes();
  popup.popupWindowOpenClose();
  footer.autoChangeYear();
  model.revealSections();
  mobileNav.mobileNav();
  review.reviewWindowOpenClose();
  how.howCaller();
  swiper.swiperCaller();
  facebookSDK.facebookSDKCaller();
};
init();

'use strict';

export const mobileNav = function () {
  const btnNavEl = document.querySelector('.btn-mobile-nav');
  const mobileNavEl = document.querySelector('.mobile__nav');
  const header = document.querySelector('.header');
  const mobileNavLinkEl = document.querySelectorAll('.mobile__nav--link');

  btnNavEl.addEventListener('click', function () {
    mobileNavEl.classList.toggle('nav-open');
    header.classList.toggle('nav-open');
  });
  mobileNavLinkEl.forEach(link =>
    link.addEventListener('click', function () {
      mobileNavEl.classList.toggle('nav-open');
    })
  );
};

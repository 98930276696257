'use strict';

export const swiperCaller = function () {
  const swiper = new Swiper('.mySwiper', {
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: 'true',
      dynamicBullets: 'true',
    },

    autoHeight: 'true',
    speed: '500',
  });
  swiper.init();
};

export const DEFAULT_ANIMATION = `slide-in-left`;
export const SECONDARY_ANIMATION = `slide-in-right`;
export const TERTIARY_ANIMATION = `slide-in-blurred-bottom`;
export const WOBBLE_ANIMATION = `wobble-hor-bottom`;
export const DEFAULT_ANIMATION_DELAY = 175;
export const BIG_ANIMATION_DELAY = 1500;
export const MEDIA_QUERY_900_PX = window.matchMedia('(max-width: 900px)');
export const MEDIA_QUERY_600_PX = window.matchMedia('(min-width: 600px)');
export const MEDIA_QUERY_450_PX = window.matchMedia('(max-width: 450px)');
export const MEDIA_QUERY_350_PX = window.matchMedia('(max-width: 350px)');

export const accessToken =
  'EAAKINZBwxUbgBOxrPzQrQ4UbZBLIn7HQTzyFmGFBEjKnKvjttXy2ZCpvUzkRBIYJKM1NllHWa6PrDkajmL8knibBDreYI1m2mapPGGDjOleBFcM9r3lbqZAJI06KUCoZBfVzYSPgZBddreYcVfR8VHPpUXQlHoSdNPOgvr9jZBB8OcoR1v151mZC1oRO91vZBZAHtJpculQvYBnCdh0BkG';
export const accountId = '17841439670789638';

import { data } from 'autoprefixer';
import icons from '../../img/sprite.svg';
import { swiperCaller } from '../views/swiper2';
import { accessToken, accountId } from '../config';

export const facebookSDKCaller = function () {
  let dataArr = [];
  let mediaData = [];
  const sectionGallery = document.querySelector('.section-gallery');

  const galleryContainer = document.querySelector('.gallery__container');

  const hasUppercase = function (str) {
    return /[А-ЯЁЄІЇЙ]/.test(str);
  };
  const firstLetterUppercase = function (str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const headingGenerator = function (string) {
    // Find the first word with a hashtag
    const start = string.indexOf('#');
    const end = string.indexOf(' ', start);
    // console.log(start, end);

    if (start === -1 || end === -1) {
      return 'Опалення'; // No hashtag found
    }

    // Remove the hashtag and split the word into two
    const word = string.slice(start + 1, end);
    if (hasUppercase(word)) {
      const twoWords = word.split(/(?=[А-ЯЁЄІЇ])/);
      const newResult = twoWords.map((str, i) => {
        if (i === 0) {
          return firstLetterUppercase(str);
        } else {
          return str.replace(/\n/g, '').toLowerCase();
        }
      });
      return newResult.join(' ');
    } else {
      const capitalized = firstLetterUppercase(word);
      return capitalized;
    }
  };
  const captionGenerator = function (text) {
    const index = text.indexOf('#'); // find the index of the "#" character
    const newCaption = text.substring(0, index); // extract the substring from the beginning of the original string up to the index of the "#" character
    return newCaption;
  };

  const markupCard = function (heading, text, link) {
    return `<div class="gallery__card">
    <div class="swiper2 mySwiper2">
      <div class="swiper-wrapper slide-holder">
      </div>
      <div class="swiper-button-next"></div>
      <div class="swiper-button-prev"></div>
    </div>
    <h4 class="gallery__card__heading">${heading}</h4>
    <p class="gallery__card__text">${text}</p>
    <a
      class="gallery__card__logo"
      href="${link}"
      target="_blank"
    >
      <svg class="gallery__card__logo--icon">
        <use xlink:href="${icons}#icon-instagram-logo"></use></svg
    ></a>
  </div>`;
  };

  const markupSlide = function (src) {
    return `<div class="swiper-slide">
  <img
    src="${src}"
    class="card__img"
    loading="lazy"
  />
  </div>`;
  };

  const backupMakrup = ` <div class="gallery__card">
  <div class="swiper2 mySwiper2">
    <div class="swiper-wrapper">
      <div class="swiper-slide">
        <img
          src="/img/card-1-lazy.webp"
          data-src="/img/card-1.webp"
          class="card__img lazy-img"
          alt="Picture 1"
          loading="lazy"
        />
      </div>
      <div class="swiper-slide">
        <img
          src="/img/card-1.2.jpg"
          data-src="/img/card-1.2.jpg"
          class="card__img lazy-img"
          alt="Picture 1"
          loading="lazy"
        />
      </div>
    </div>
  </div>
  <h4 class="gallery__card__heading">Водоочистка</h4>
  <p class="gallery__card__text">
    Для очищення води встановлено фільтр грубої очистки, колона
    знезалізнення, станція зворотного осмосу
    <strong>Ecosoft MO 5000</strong> та станція корекції рівня pH. Для
    запасу очищеної води передбачена ємність об'ємом 1 м³. Приємно коли
    чиста вода тече з крану.
  </p>
  <a
    class="gallery__card__logo"
    href="https://www.instagram.com/p/CZ40oFUtfLM/?utm_source=ig_web_copy_link"
    target="_blank"
  >
    <svg class="gallery__card__logo--icon">
      <use xlink:href="/img/sprite.svg#icon-instagram-logo"></use></svg
  ></a>
</div>
<div class="gallery__card">
  <div class="swiper2 mySwiper2">
    <div class="swiper-wrapper">
      <div class="swiper-slide">
        <img
          src="/img/card-2-lazy.webp"
          data-src="/img/card-2.webp"
          class="card__img lazy-img"
          alt="Picture 2"
          loading="lazy"
        />
      </div>
    </div>
  </div>
  <h4 class="gallery__card__heading">Опалення</h4>
  <p class="gallery__card__text">
    Система включає газовий конденсаційний котел, електричний котел,
    бойлер непрямого нагріву 200л, насосні групи, гідравлічна стрілка
    автоматика VR 70 - все <strong>Vaillant </strong>. Німецька якість,
    економічність, сучасний дизайн та технологічність у кожній деталі.
  </p>
  <a
    class="gallery__card__logo"
    href="https://www.instagram.com/p/CXTK4-xtePz/?utm_source=ig_web_copy_link"
    href="https://www.instagram.com/p/CZ40oFUtfLM/?utm_source=ig_web_copy_link"
    target="_blank"
  >
    <svg class="gallery__card__logo--icon">
      <use xlink:href="/img/sprite.svg#icon-instagram-logo"></use></svg
  ></a>
</div>
<div class="gallery__card">
  <div class="swiper2 mySwiper2">
    <div class="swiper-wrapper">
      <div class="swiper-slide">
        <img
          src="/img/card-3-lazy.webp"
          data-src="/img/card-3.webp"
          class="card__img lazy-img"
          alt="Picture 3"
          loading="lazy"
        />
      </div>
    </div>
  </div>
  <h4 class="gallery__card__heading">Каналізація</h4>
  <p class="gallery__card__text">
    Система каналізації з сучасних ПВХ матеріалів забезпечить надійність
    і довгий строк експлуатації. Основа з піску відсипана та
    утрамбована, труби каналізації укладені та всі засипані піском.
    Труби використані
    <strong>Ostendorf</strong>
    Німеччина. Все технологічно та якісно. Система перевірена перед
    зворотньою засипкою.
  </p>
  <a
    class="gallery__card__logo"
    href="https://www.instagram.com/p/CVlPxaSNSfN/?utm_source=ig_web_copy_link"
    target="_blank"
  >
    <svg class="gallery__card__logo--icon">
      <use xlink:href="/img/sprite.svg#icon-instagram-logo"></use></svg
  ></a>
</div>
<div class="gallery__card">
  <div class="swiper2 mySwiper2">
    <div class="swiper-wrapper">
      <div class="swiper-slide">
        <img
          src="/img/card-4-lazy.webp"
          data-src="/img/card-4.webp"
          class="card__img lazy-img"
          alt="Picture 4"
          loading="lazy"
        />
      </div>
    </div>
  </div>
  <h4 class="gallery__card__heading">Тепловий насос</h4>
  <p class="gallery__card__text">
    На об'єкті встановлено тепловий насос
    <strong>Viessmann Vitocal 100s</strong> , газовий котел
    <strong>Vaillant</strong>, буферна ємність
    <strong>Galmet</strong> на 300 л. та насоси
    <strong>Grungfos</strong>. Охолодження здійснюється за рахунок
    настінних фанкойлів <strong>Daikin</strong>. Така система дозволить
    насолоджуватись комфортом в кожній кімнаті.
  </p>
  <a
    class="gallery__card__logo"
    href="https://www.instagram.com/p/CDrdhEShPus/?utm_source=ig_web_copy_link"
    target="_blank"
  >
    <svg class="gallery__card__logo--icon">
      <use xlink:href="/img/sprite.svg#icon-instagram-logo"></use></svg
  ></a>
</div>
<div class="gallery__card">
  <div class="swiper2 mySwiper2">
    <div class="swiper-wrapper">
      <div class="swiper-slide">
        <img
          src="/img/card-5-lazy.webp"
          data-src="/img/card-5.png"
          class="card__img lazy-img"
          alt="Picture 5"
          loading="lazy"
        />
      </div>
    </div>
  </div>
  <h4 class="gallery__card__heading">Тепла підлога</h4>
  <p class="gallery__card__text">
    Робота виконана з якісних матеріалів. Площа квартири близько 180 м²,
    майже всюди вітражи. Чудовий вид з вікна, але за це задоволення
    прийдеться платити чималі кошти ! Було прийнято рішення в
    майбутньому встановити тепловий насос типу повітря-вода, щоб мати
    можливість жити в теплі і витрачати невеликі гроші.
  </p>
  <a
    class="gallery__card__logo"
    href="https://www.instagram.com/p/Cj_HGsCtFPc/"
    target="_blank"
  >
    <svg class="gallery__card__logo--icon">
      <use xlink:href="/img/sprite.svg#icon-instagram-logo"></use></svg
  ></a>
</div>
<div class="gallery__card">
  <div class="swiper2 mySwiper2">
    <div class="swiper-wrapper">
      <div class="swiper-slide">
        <img
          src="/img/card-6-lazy.webp"
          data-src="/img/card-6.webp.png"
          class="card__img lazy-img"
          alt="Picture 6"
          loading="lazy"
        />
      </div>
    </div>
  </div>
  <h4 class="gallery__card__heading">Водопостачання</h4>
  <p class="gallery__card__text">
    Встановлено інсталяцію унітазу Geberit, вбудована частина змішувача
    умивальника та душової системи Hansgrohe. Виконано променеву систему
    водопостачання (до кожного сантехнічного приладу підходить окрема
    трубочка). Труби та фітинги Rehau (дорого та якісно).
  </p>
  <a
    class="gallery__card__logo"
    href="https://www.instagram.com/p/CfCZMDBt8Bt/"
    target="_blank"
  >
    <svg class="gallery__card__logo--icon">
      <use xlink:href="/img/sprite.svg#icon-instagram-logo"></use></svg
  ></a>
</div>
<div class="gallery__card">
  <div class="swiper2 mySwiper2">
    <div class="swiper-wrapper">
      <div class="swiper-slide">
        <img
          src="/img/card-7-lazy.webp"
          data-src="/img/card-7.webp.png"
          class="card__img lazy-img"
          alt="Picture 7"
          loading="lazy"
        />
      </div>
    </div>
  </div>
  <h4 class="gallery__card__heading">Теплі стіни</h4>
  <p class="gallery__card__text">
    Використовували технологію Rehau. Стіни не тільки працюють на
    опалення, але й можемо їх використовувати на охолодження. Тоді це
    будуть холодні стіни. Цікава, дорога, практична та дуже ефективна
    система. Найсучасніші та найкращі рішення обирають клієнти які
    турбуються і думають про майбутнє.
  </p>
  <a
    class="gallery__card__logo"
    href="https://www.instagram.com/p/ChIQqCbND-O/"
    target="_blank"
  >
    <svg class="gallery__card__logo--icon">
      <use xlink:href="/img/sprite.svg#icon-instagram-logo"></use></svg
  ></a>
</div>
<div class="gallery__card">
  <div class="swiper2 mySwiper2">
    <div class="swiper-wrapper">
      <div class="swiper-slide">
        <img
          src="/img/card-8-lazy.webp"
          data-src="/img/card-8.webp.png"
          class="card__img lazy-img"
          alt="Picture 8"
          loading="lazy"
        />
      </div>
    </div>
  </div>
  <h4 class="gallery__card__heading">Тепловий насоc</h4>
  <p class="gallery__card__text">
    Альтернативні, сучасні, технологічні джерела теплопостачання – це
    напрямок розвитку України. Тепловий насос Vaillant - це саме про це.
    Круті характеристики та якість просто на висоті! На даному об'єкті
    він працюватиме на обігрів будинку. Можливо додати гаряче
    водопостачання та систему охолодження.
  </p>
  <a
    class="gallery__card__logo"
    href="https://www.instagram.com/p/CfPWJ9hNzGJ/"
    target="_blank"
  >
    <svg class="gallery__card__logo--icon">
      <use xlink:href="/img/sprite.svg#icon-instagram-logo"></use></svg
  ></a>
</div>
</div>
<div class="btn__gallery__box">
<a
  class="btn btn--full"
  href="https://www.instagram.com/oleg_svystunov/"
  target="_blank"
>
  <p class="paragraph">Більше фотографій моїх об'єктів</p>
</a>
</div>`;

  const fetchAPI = async function () {
    try {
      fetch(
        `https://graph.facebook.com/v16.0/${accountId}/media?fields=id,caption,permalink,media_type,media_url,thumbnail_url,children{media_url}&access_token=${accessToken}`
      )
        .then(response => response.json())
        .then(data => {
          //store it to data
          const dataEl = data.data;
          // console.log(dataEl);

          const dataWithoutVideo = [];
          dataEl.map((el, i) => {
            if (el.media_type !== 'VIDEO') {
              dataWithoutVideo.push(el);
            }
          });

          //take first 8
          const firstEight = dataWithoutVideo.slice(0, 8);
          //save it in new object
          firstEight.forEach(data => {
            dataArr.push({
              mediaUrl: data.media_url,
              caption: data.caption,
              permalink: data.permalink,
              children: data.children,
            });
          });
          // console.log(firstEight);

          //insert in html
          dataArr.forEach(el => {
            // console.log(el);
            const cardGenerator = function () {
              //Create a Card
              galleryContainer.insertAdjacentHTML(
                'afterbegin',
                markupCard(
                  headingGenerator(el.caption),
                  captionGenerator(el.caption),
                  el.permalink
                )
              );

              //Create a slides
              const slideHolder = document.querySelector('.slide-holder');

              if (el.children == undefined) {
                const childImg = document.createElement('img');

                childImg.src = el.mediaUrl;
                childImg.alt = `Picture 1`;
                childImg.classList.add('card__img');
                childImg.addEventListener('load', function () {
                  slideHolder.insertAdjacentHTML(
                    'afterbegin',
                    markupSlide(childImg.src, `Picture 1`)
                  );
                });
                console.log(childImg);
              } else {
                el.children.data.forEach((picture, i) => {
                  const childImg = document.createElement('img');
                  childImg.src = picture.media_url;
                  childImg.alt = `Picture ${i + 1}`;
                  childImg.classList.add('card__img');
                  childImg.addEventListener('load', function () {
                    slideHolder.insertAdjacentHTML(
                      'afterbegin',
                      markupSlide(childImg.src, `Picture ${i + 1}`)
                    );
                  });
                });
              }
            };
            cardGenerator();
          });
          swiperCaller();
        });
    } catch (err) {
      throw err;
    }
  };

  // Fetch the user's media using the Instagram Graph API
  const callback = function (entries, observer) {
    const [entry] = entries;
    if (!entry.isIntersecting) return;
    fetchAPI();
    //STOP OBSERVING
    observer.unobserve(entry.target);
  };

  const observer = new IntersectionObserver(callback, {
    root: null,
    threshold: 0,
  });

  observer.observe(sectionGallery);
};

'use strict';

import { fadeAnimation } from '../helpers';

const header = document.querySelector('.header');
const headerNavLinkClassName =
  header.children[1].children[0].children[0].children[0].className;

const headerClassName = header.className;

// Menu fade animation
export const headerFade = function () {
  fadeAnimation(
    headerNavLinkClassName,
    headerClassName,
    header,
    headerNavLinkClassName
  );
};
